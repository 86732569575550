import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { Button, Checkbox } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";

import { MainLayout } from "layouts";
import styles from "./styles.module.scss";
import { CommunityInvite, EProcessToJoinCommunityStage, TStore } from "models";

import {
	setSelectedCommunityToJoinMemberType,
	setSelectedCommunityToJoinSkills,
	setSelectedCommunityToJoinTags,
	setSelectedCommunityToJoinMemberPlan,
	storeTagsAndMemberType,
	notify,
} from "store";
import { getMemberPlanPriceOptionByCurrency, isItAWhiteLabel } from "utils";

const stateSelectorHandle = createSelector(
	(state: TStore) => state.communities.selectedCommunityToJoinInfo,
	(state: TStore) => state.communities?.meta?.invites,
	(selectedCommunityToJoinInfo, invites) => ({
		selectedCommunityToJoinInfo,
		invites,
	}),
);

const CommunityDetails = ({ children }: any) => {
	const dispatch = useDispatch();
	const stateSelector = useCallback(stateSelectorHandle, []);
	const { selectedCommunityToJoinInfo, invites } = useSelector(stateSelector);
	const navigate = useNavigate();
	const {
		selectedTags,
		selectedSkills,
		tags,
		skills,
		uses_stripe,
		memberTypes,
		id,
		selectedMemberType,
	} = selectedCommunityToJoinInfo || {};
	const [errorNoMemberTypesSelected, setEerrorNoMemberTypesSelected] =
		useState(false);
	const [errorNoInterestsSelected, setErrorNoInterestsSelected] =
		useState(false);
	const [goToGroupsLoading, setGoToGroupsLoading] = useState(false);

	const invitationMemberTypeData: string | null = useMemo(() => {
		if (invites) {
			let checkIfSelectedCommuntiyIsAnInvitation;
			checkIfSelectedCommuntiyIsAnInvitation = invites.find(
				(item) => item.id === id,
			);
			if (checkIfSelectedCommuntiyIsAnInvitation) {
				return checkIfSelectedCommuntiyIsAnInvitation.invite_member_type;
			} else {
				return null;
			}
		} else {
			return null;
		}
	}, [id, invites]);

	const updateSelectedMemberType = useCallback(
		(memberType: string) => {
			if (memberType && errorNoMemberTypesSelected) {
				setEerrorNoMemberTypesSelected(false);
			}
			dispatch(
				setSelectedCommunityToJoinMemberType({
					memberType: memberType,
				}),
			);
		},
		[dispatch, errorNoMemberTypesSelected],
	);

	const updateSelectedInterests = useCallback(
		(selectedInterests: CheckboxValueType[]) => {
			if (selectedInterests.length > 0 && errorNoInterestsSelected) {
				setErrorNoInterestsSelected(false);
			}
			dispatch(
				setSelectedCommunityToJoinTags({
					tags: selectedInterests as string[],
				}),
			);
		},
		[dispatch, errorNoInterestsSelected],
	);

	const updateSelectedSkills = useCallback(
		(selectedSkills: CheckboxValueType[]) => {
			dispatch(
				setSelectedCommunityToJoinSkills({
					skills: selectedSkills as string[],
				}),
			);
		},
		[dispatch],
	);

	const goToGroups = useCallback(() => {
		if (!selectedCommunityToJoinInfo?.id) {
			return;
		}
		if (!selectedMemberType) {
			setEerrorNoMemberTypesSelected(true);
			return;
		}
		if (!selectedTags || selectedTags?.length === 0) {
			setErrorNoInterestsSelected(true);
			return;
		}
		setGoToGroupsLoading(true);

		let memberPlanPrice = getMemberPlanPriceOptionByCurrency(
			selectedCommunityToJoinInfo.selectedCurrency,
			selectedCommunityToJoinInfo.selected_member_plan?.price_options,
		);
		let stripePlanPriceId =
			selectedCommunityToJoinInfo.privacy === "close"
				? memberPlanPrice?.stripe_id
				: null;

		dispatch(
			storeTagsAndMemberType({
				communityId: selectedCommunityToJoinInfo.id,
				tags: selectedTags,
				skill_tags: selectedSkills,
				membershipType: selectedMemberType,
				stripePlanPriceId,
				successCallback: () => {
					navigate(
						`${isItAWhiteLabel() ? "/joining/" : "/communities/"}${
							selectedCommunityToJoinInfo.id
						}/${EProcessToJoinCommunityStage.groups}`,
					);

					setGoToGroupsLoading(false);
				},
				errorCallback: () => {
					setGoToGroupsLoading(false);
					notify(
						"error",
						"Something went wrong.",
						"Please try again later.",
					);
					if (isItAWhiteLabel()) {
						navigate("/joining/init", {
							state: {
								error: true,
							},
						});
					} else {
						navigate("/communities/all");
					}
				},
			}),
		);
	}, [
		dispatch,
		selectedCommunityToJoinInfo,
		selectedMemberType,
		selectedSkills,
		selectedTags,
		navigate,
	]);

	const back = useCallback(() => {
		if (uses_stripe != null || !selectedCommunityToJoinInfo) {
			console.error("Missing data!");
			return;
		}
		if (
			!uses_stripe ||
			(uses_stripe && selectedCommunityToJoinInfo.invite_member_type)
		) {
			navigate(isItAWhiteLabel() ? "/joining/init" : "/communities/all");
		} else {
			dispatch(
				setSelectedCommunityToJoinMemberType({
					memberType: "",
				}),
			);
			dispatch(setSelectedCommunityToJoinMemberPlan({ plan: null }));
			navigate(-1);
		}
	}, [dispatch, navigate, uses_stripe, selectedCommunityToJoinInfo]);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		if (!invites || selectedMemberType) return;

		let checkIfSelectedCommuntiyIsAnInvitation: CommunityInvite | undefined;
		checkIfSelectedCommuntiyIsAnInvitation = invites.find(
			(item) => item.id === id,
		);

		if (
			!checkIfSelectedCommuntiyIsAnInvitation ||
			!checkIfSelectedCommuntiyIsAnInvitation.invite_member_type
		)
			return;

		const invitationMemberType: string | null =
			checkIfSelectedCommuntiyIsAnInvitation.invite_member_type;

		if (invitationMemberType) {
			dispatch(
				setSelectedCommunityToJoinMemberType({
					memberType: invitationMemberType,
				}),
			);
		}
	}, [dispatch, id, invites, selectedMemberType]);

	return (
		<MainLayout>
			<section className={styles.communityWrapper}>
				<div className={styles.tagsArea}>
					{!uses_stripe && memberTypes?.length > 0 && (
						<>
							<div className={styles.label}>{"Member Types"}</div>
							<div
								className={classNames(styles.subTitle, {
									[styles.errorText]:
										errorNoMemberTypesSelected,
									[styles.greyText]:
										!errorNoMemberTypesSelected,
								})}
							>
								{"Please choose your member type."}
							</div>

							<div
								id="checkbox-group"
								className={classNames(
									styles.checkboxGroup,
									styles.accents,
								)}
							>
								{memberTypes.map(
									(item: string, index: number) => (
										<div
											key={`member_type_${index}`}
											onClick={
												!invitationMemberTypeData
													? (e: any) =>
															updateSelectedMemberType(
																e.target.dataset
																	.value,
															)
													: () => {}
											}
											data-value={item}
											className={classNames(
												styles.singleCheckbox,
												{
													[styles.selected]:
														selectedMemberType ===
														item,
													[styles.disabled]:
														invitationMemberTypeData &&
														invitationMemberTypeData !==
															item,
												},
											)}
										>
											{item}
										</div>
									),
								)}
							</div>
						</>
					)}
					{tags && tags?.length > 0 && (
						<>
							<div className={styles.label}>{"Interests"}</div>

							<div
								className={classNames(styles.subTitle, {
									[styles.errorText]:
										errorNoInterestsSelected,
									[styles.greyText]:
										!errorNoInterestsSelected,
								})}
							>
								{"Please choose at least one tag."}
							</div>

							<div
								id="checkbox-interests"
								className={classNames(
									styles.checkboxGroup,
									styles.accents,
								)}
							>
								<Checkbox.Group
									name={"checkbox-group"}
									options={tags.map((item: any) => item.name)}
									onChange={updateSelectedInterests}
									value={selectedTags}
								/>
							</div>
						</>
					)}
					{skills && skills.length > 0 && (
						<>
							<div className={styles.label}>{"Skills"}</div>

							<div
								id="checkbox-skills"
								className={classNames(
									styles.checkboxGroup,
									styles.accents,
								)}
							>
								<Checkbox.Group
									options={skills.map((item) => item.name)}
									onChange={updateSelectedSkills}
									value={selectedSkills}
								/>
							</div>
						</>
					)}
				</div>
				<div className={styles.btnContainer}>
					<Button
						htmlType="submit"
						className={styles.antBtn}
						onClick={back}
						style={{
							marginLeft: 0,
							background: "#fff",
							color: "#000",
							border: "1px solid #000",
						}}
					>
						{"Back"}
					</Button>
					<Button
						htmlType="submit"
						className={styles.antBtn}
						onClick={goToGroups}
						loading={goToGroupsLoading}
					>
						{"Next"}
					</Button>
				</div>
			</section>
		</MainLayout>
	);
};

export default memo(CommunityDetails);
