import {
	IAuthState,
	EAuthActionTypes,
	TAuthActions,
	TAppActions,
} from "models";

const initial: IAuthState = {
	loading: false,
	credentials: undefined,
	communityInvitation: null,
	deepLink: null,
	memberType: undefined,
};

export function authReducer(
	state = initial,
	action: TAuthActions | TAppActions,
): IAuthState {
	switch (action.type) {
		case EAuthActionTypes.authLoadingSet: {
			return {
				...state,
				loading: action.payload,
			};
		}
		case EAuthActionTypes.updateInvite: {
			if (!action.payload) {
				return state;
			}
			return {
				...state,
				communityInvitation: action.payload,
			};
		}
		case EAuthActionTypes.authUserSet: {
			return {
				...state,
				...action.payload,
			};
		}
		case EAuthActionTypes.setDeepLinkedSignUp: {
			return {
				...state,
				deepLink: action.payload,
			};
		}
		case EAuthActionTypes.setUserCurrentSubscription: {
			const { currentPlanId, currenSubscription } = action.payload;
			return {
				...state,
				credentials: {
					...state.credentials?.invite,
					...state.credentials?.deepLink,
					user: {
						...state.credentials?.user,
						current_plan_id: currentPlanId,
						current_subscription: currenSubscription,
					},
				},
			};
		}

		case EAuthActionTypes.setMemberType: {
			return {
				...state,
				memberType: action.payload,
			};
		}

		default:
			return state;
	}
}
