import { memo, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { useNavigate } from "react-router-dom";

import { OnboardingLayout } from "layouts";
import styles from "./styles.module.scss";
import { MemberType, Location } from "./components";
import InterestsAnsSkills from "./components/interestsAndSkills";
import {
	setSelectedCommunityToJoinTags,
	setSelectedCommunityToJoinSkills,
	storeTagsAndMemberType,
	notify,
	saveUserProfile,
	setSelectedCommunityToJoinMemberType,
	setSelectedCommunityToJoinUserLocation,
	setCommunityInterests,
	setSelectedCommunityToJoinProgressBar,
} from "store";
import {
	EJoinCommunityOnboardingSteps,
	EProcessToJoinCommunityStage,
	TStore,
} from "models";
import { Loading } from "components";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import {
	getMemberPlanPriceOptionByCurrency,
	rollbar,
	isItAWhiteLabel,
} from "utils";
import FormWrapper from "components/FormWrapper";
import FileUploader from "components/FileUploader";
import CustomTag from "components/CustomTag";

const stateSelectorHandle = createSelector(
	(state: TStore) => state.communities.selectedCommunityToJoinInfo,
	(state: TStore) => state.auth.credentials?.user,
	(selectedCommunityToJoinInfo, authenticatedUser) => ({
		selectedCommunityToJoinInfo,
		authenticatedUser,
	}),
);

const JoinCommunityUserSetUp = () => {
	const stateSelector = useCallback(stateSelectorHandle, []);
	const { selectedCommunityToJoinInfo, authenticatedUser } =
		useSelector(stateSelector);
	const dispatch = useDispatch();
	const [goToGroupsLoading, setGoToGroupsLoading] = useState(false);
	const navigate = useNavigate();
	const [errorNoInterestsSelected, setErrorNoInterestsSelected] =
		useState(false);
	const [errorNoMemberTypeSelected, setErrorNoMemberTypeSelected] =
		useState(false);
	const [errorNoLocationAdded, setErrorNoLocationAdded] = useState(false);
	const {
		selectedTags = [],
		tags = [],
		skills = [],
		selectedSkills = [],
		selectedMemberType,
		appearance,
	} = selectedCommunityToJoinInfo || {};

	const updateSelectedInterests = useCallback(
		(selectedInterests: CheckboxValueType[]) => {
			if (selectedInterests.length > 0 && errorNoInterestsSelected) {
				setErrorNoInterestsSelected(false);
			}
			dispatch(
				setSelectedCommunityToJoinTags({
					tags: selectedInterests as string[],
				}),
			);
		},
		[dispatch, errorNoInterestsSelected],
	);

	const updateSelectedSkills = useCallback(
		(selectedSkills: CheckboxValueType[]) => {
			dispatch(
				setSelectedCommunityToJoinSkills({
					skills: selectedSkills as string[],
				}),
			);
		},
		[dispatch],
	);

	const communityName = selectedCommunityToJoinInfo
		? selectedCommunityToJoinInfo.name
		: "Ugenie";

	const back = useCallback(() => {
		if (!selectedCommunityToJoinInfo) {
			console.error("Missing data!");
			return;
		}
		if (selectedCommunityToJoinInfo.uses_stripe) {
			navigate(-1);
		} else {
			navigate(-1);
		}
	}, [navigate, selectedCommunityToJoinInfo]);

	const completeTheProfile = useCallback(() => {
		dispatch(
			setCommunityInterests({
				communityId: selectedCommunityToJoinInfo.id,
				successCallback: () => {},
				errorCallback: () => {
					notify(
						"error",
						"Something went wrong.",
						"Please try again later.",
					);
					if (isItAWhiteLabel()) {
						navigate("/joining/init", {
							state: {
								error: true,
							},
						});
					} else {
						navigate("/communities/all");
					}
				},
			}),
		);
	}, [dispatch, navigate, selectedCommunityToJoinInfo.id]);

	const validateUserData = useCallback(() => {
		if (!selectedCommunityToJoinInfo) {
			throw new Error("Missing params!");
		}
		let throwError = false;

		if (!selectedCommunityToJoinInfo.uses_stripe && !selectedMemberType) {
			// 	setErrorNoMemberTypeSelected(true);
			// 	throwError = true;
		}
		if (selectedTags.length === 0) {
			throwError = true;
			setErrorNoInterestsSelected(true);
		}

		if (!selectedCommunityToJoinInfo.userProfileLocation?.address) {
			throwError = true;
			setErrorNoLocationAdded(true);
		}
		if (!selectedMemberType) {
			throwError = true;
			setErrorNoMemberTypeSelected(true);
		}
		if (throwError) {
			throw new Error("Missing params!");
		}
	}, [selectedCommunityToJoinInfo, selectedMemberType, selectedTags.length]);

	const goToGroups = useCallback(() => {
		try {
			validateUserData();
			setGoToGroupsLoading(true);

			// edit the profile by saving the image and the location if these has been added
			if (
				selectedCommunityToJoinInfo.userProfileLocation?.address ||
				selectedCommunityToJoinInfo.userProfilePicture
			) {
				dispatch(
					saveUserProfile({
						isFromOnboarding: true,
						avatar:
							selectedCommunityToJoinInfo.userProfilePicture
								?.profileImage ?? undefined,
						...selectedCommunityToJoinInfo.userProfileLocation,
						dontRedirect: true,
						onSuccessCallback: () => {},
						onErrorCallback: () => {
							setGoToGroupsLoading(false);
							notify(
								"error",
								"Something went wrong.",
								"Please try again later.",
							);
							if (isItAWhiteLabel()) {
								navigate("/joining/init", {
									state: {
										error: true,
									},
								});
							} else {
								navigate("/communities");
							}
							throw new Error("The profile couldn't be saved!");
						},
						communityId: selectedCommunityToJoinInfo.id,
						industry: "",
						profession: "",
					}),
				);
			}

			let memberPlanPrice = getMemberPlanPriceOptionByCurrency(
				selectedCommunityToJoinInfo.selectedCurrency,
				selectedCommunityToJoinInfo.selected_member_plan?.price_options,
			);

			let stripePlanPriceId = memberPlanPrice?.stripe_id;

			dispatch(
				storeTagsAndMemberType({
					communityId: selectedCommunityToJoinInfo.id,
					tags: selectedTags,
					skill_tags: selectedSkills,
					membershipType: selectedMemberType!,
					stripePlanPriceId,
					successCallback: () => {
						completeTheProfile();
						dispatch(
							setSelectedCommunityToJoinProgressBar({
								onboardingStep:
									EJoinCommunityOnboardingSteps[
										"Select groups"
									],
							}),
						);
						setGoToGroupsLoading(false);
						navigate(
							`${
								isItAWhiteLabel()
									? "/joining/"
									: "/communities/"
							}${selectedCommunityToJoinInfo.id}/${
								EProcessToJoinCommunityStage.groups
							}`,
						);
					},
					errorCallback: () => {
						setGoToGroupsLoading(false);
						notify(
							"error",
							"Something went wrong.",
							"Please try again later.",
						);
						if (isItAWhiteLabel()) {
							navigate("/joining/init", {
								state: {
									error: true,
								},
							});
						} else {
							navigate("/communities");
						}
					},
				}),
			);
		} catch (error) {
			rollbar.error(error);
			console.error(error);
		}
	}, [
		dispatch,
		navigate,
		validateUserData,
		selectedCommunityToJoinInfo,
		selectedMemberType,
		selectedSkills,
		selectedTags,
		completeTheProfile,
	]);

	const updateSelectedMemberType = useCallback(
		(memberType: string) => {
			if (!memberType) return;

			if (errorNoMemberTypeSelected) {
				setErrorNoMemberTypeSelected(false);
			}

			dispatch(
				setSelectedCommunityToJoinMemberType({
					memberType: memberType,
				}),
			);
		},
		[dispatch, errorNoMemberTypeSelected],
	);

	useEffect(() => {
		if (!authenticatedUser) return;
		const { location, latitude, longitude } = authenticatedUser;
		dispatch(
			setSelectedCommunityToJoinUserLocation({
				address: location,
				latitude: latitude,
				longitude: longitude,
			}),
		);
	}, [authenticatedUser, dispatch]);

	return goToGroupsLoading ? (
		<Loading />
	) : (
		<>
			<OnboardingLayout
				headerTitle={"Set up your Profile"}
				headerSubtitle={`Let ${communityName} know a little more about you before you join.`}
				footerLayout={false}
				handleBack={back}
				handleNext={goToGroups}
			>
				<FormWrapper maxWidth="552px" paddingTop="0px">
					<MemberType
						selectedMemberType={selectedMemberType}
						errorNoMemberTypeSelected={errorNoMemberTypeSelected}
						updateSelectedMemberType={updateSelectedMemberType}
						setErrorNoMemberTypeSelected={
							setErrorNoMemberTypeSelected
						}
					/>
					<div className={styles.pictureWrapper}>
						<p>Add profile picture</p>
						<p>
							<CustomTag>Optional</CustomTag>
						</p>
					</div>

					<FileUploader
						UploadText={"Drag & Drop your files here or"}
						selectedCommunityToJoinInfo={
							selectedCommunityToJoinInfo
						}
					/>
					<Location
						selectedCommunityToJoinInfo={
							selectedCommunityToJoinInfo
						}
						setErrorNoLocationAdded={setErrorNoLocationAdded}
						errorNoLocationSelected={errorNoLocationAdded}
					/>
					<InterestsAnsSkills
						errorNoInterestsSelected={errorNoInterestsSelected}
						tags={tags}
						updateSelectedInterests={updateSelectedInterests}
						updateSelectedSkills={updateSelectedSkills}
						skills={skills}
						selectedTags={selectedTags}
						selectedSkills={selectedSkills}
						appearance={appearance}
					/>
				</FormWrapper>
			</OnboardingLayout>
		</>
	);
};

export default memo(JoinCommunityUserSetUp);
