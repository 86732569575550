import {
	EAuthActionTypes,
	IAuthSignUpPayload,
	IAuthSignInPayload,
	IUserOnboardingState,
	ICommunityInvitation,
	ICurrentSubscription,
	IMemberType,
} from "models";

export const authenticate = (payload: IAuthSignInPayload) => ({
	type: EAuthActionTypes.authInit,
	payload,
});

export const authenticateByToken = (payload: string) => ({
	type: EAuthActionTypes.authByToken,
	payload,
});

// export const checkAuthToken = payload => ({
//   type: AUTH_CHECK_TOKEN,
//   payload
// });
export const register = (payload: IAuthSignUpPayload) => ({
	type: EAuthActionTypes.authSignUp,
	payload,
});
export const editUserProfile = (payload: IUserOnboardingState) => ({
	type: EAuthActionTypes.authUserOnBoarding,
	payload,
});
export const setAuthenticationLoading = (payload: boolean) => ({
	type: EAuthActionTypes.authLoadingSet,
	payload,
});

export const setUser = (payload: any) => ({
	type: EAuthActionTypes.authUserSet,
	payload,
});

export const setMembershipId = (payload: string | number) => ({
	type: EAuthActionTypes.setUserMemberShipId,
	payload,
});

export const storeLogout = (payload?: any) => ({
	type: EAuthActionTypes.authSignOut,
	payload,
});

export const getInvite = (token: string) => ({
	type: EAuthActionTypes.getInvite,
	payload: { token },
});

export const setInvite = (payload: ICommunityInvitation) => ({
	type: EAuthActionTypes.updateInvite,
	payload,
});

export const setDeepLinkedSignUp = (payload: any) => ({
	type: EAuthActionTypes.setDeepLinkedSignUp,
	payload,
});

export const deleteAccount = () => ({
	type: EAuthActionTypes.deleteAccount,
});

/** The userId parameter is used for the cases when the user's id is not yet saved in the global store (e.g login process) */
export const getUserCurrentSubscriptionAction = (payload: {
	communityId: number;
	userId?: number;
}) => ({
	type: EAuthActionTypes.getUserCurrentSubscription,
	payload,
});

export const setUserCurrentSubscriptionAction = (payload: {
	currentPlanId: number;
	currenSubscription: ICurrentSubscription | string;
}) => ({
	type: EAuthActionTypes.setUserCurrentSubscription,
	payload,
});

export const getInvitationDataByCommunityLink = (payload: {
	token: string;
}) => ({
	type: EAuthActionTypes.getInvitationDataByCommunityLink,
	payload,
});

export const setAuthMemberTypeAction = (payload: IMemberType | undefined) => ({
	type: EAuthActionTypes.setMemberType,
	payload,
});
