import { memo, useCallback, useEffect } from "react";
import { createSelector } from "reselect";
import { useDispatch, useSelector } from "react-redux";
import { Radio, RadioChangeEvent } from "antd";
import classNames from "classnames";

import { TStore } from "models";
import styles from "./styles.module.scss";
import { setSelectedCommunityToJoinMemberType } from "store";
import CustomRadioButton from "components/CustomRadioButton";
import CustomTag from "components/CustomTag";

const stateSelectorHandle = createSelector(
	(state: TStore) => state.communities.selectedCommunityToJoinInfo,
	(state: TStore) => state.auth.memberType,
	(selectedCommunityToJoinInfo, memberType) => ({
		selectedCommunityToJoinInfo,
		memberType,
	}),
);

interface IMemberType {
	selectedMemberType?: string;
	errorNoMemberTypeSelected: boolean;
	updateSelectedMemberType: (memberType: string) => void;
	setErrorNoMemberTypeSelected: React.Dispatch<React.SetStateAction<boolean>>;
}

const MemberType: React.FunctionComponent<IMemberType> = ({
	updateSelectedMemberType,
	errorNoMemberTypeSelected,
}) => {
	const dispatch = useDispatch();
	const stateSelector = useCallback(stateSelectorHandle, []);
	const { selectedCommunityToJoinInfo, memberType } =
		useSelector(stateSelector);
	const {
		memberTypes = [],
		selectedMemberType,
		invite_member_type,
		uses_stripe,
		contact_email,
		owner_email,
	} = selectedCommunityToJoinInfo || {};

	useEffect(() => {
		if (
			invite_member_type &&
			(selectedMemberType === invite_member_type || !selectedMemberType)
		) {
			dispatch(
				setSelectedCommunityToJoinMemberType({
					memberType: invite_member_type,
				}),
			);
		}

		if (memberType) {
			dispatch(
				setSelectedCommunityToJoinMemberType({
					memberType: memberType.name,
				}),
			);
		}
	}, [dispatch, invite_member_type, selectedMemberType, memberType]);

	return (
		<div className={styles.container}>
			{
				<>
					<div
						className={classNames(
							styles.boldWeightLabel,
							styles.rowFlexStart,
						)}
					>
						<p>{uses_stripe ? "Member Plan" : "Member Type"}</p>

						{uses_stripe || invite_member_type ? (
							<CustomTag color="processing">
								{selectedMemberType}
							</CustomTag>
						) : null}
					</div>
					{invite_member_type && (contact_email || owner_email) ? (
						<div className={styles.greySmallText}>
							This is the pre-assigned membership type which can
							only be changed by a community admin. If something
							is not right contact&nbsp;
							<a
								className={styles.underlineDecoration}
								href={`mailto:${
									contact_email ? contact_email : owner_email
								}`}
								rel="noreferrer"
							>
								{contact_email ? contact_email : owner_email}
							</a>
						</div>
					) : null}
					{uses_stripe || invite_member_type ? null : (
						<>
							<div
								className={classNames(styles.smallText, {
									[styles.errorText]:
										errorNoMemberTypeSelected,
									[styles.greyText]:
										!errorNoMemberTypeSelected,
								})}
							>
								{"Please select a member type."}
							</div>
							<Radio.Group
								style={{
									display: "flex",
									gap: "8px",
									flexWrap: "wrap",
								}}
								disabled={!!memberType || !!invite_member_type}
								defaultValue={selectedMemberType}
								value={selectedMemberType}
								onChange={(e: RadioChangeEvent) =>
									updateSelectedMemberType(e.target.value)
								}
							>
								{memberTypes.map(
									(item: string, index: number) => (
										<CustomRadioButton
											value={item}
											key={`member_type_${index}`}
										>
											{item}
										</CustomRadioButton>
									),
								)}
							</Radio.Group>
						</>
					)}
				</>
			}
		</div>
	);
};

export default memo(MemberType);
