import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import createSagaMiddleware from "redux-saga";
import { createBrowserHistory } from "history";
import { createRouterMiddleware } from "@lagunovsky/redux-react-router";
import { encryptTransform } from "redux-persist-transform-encrypt";

// import * as firebase from "firebase";
// import "@firebase/firestore"; // 👈 If you're using firestore
// import ReduxSagaFirebase from "redux-saga-firebase";
import storage from "redux-persist/lib/storage";
import { persistStore, createTransform, persistReducer } from "redux-persist";

import sagas from "./sagas";
import { rootReducer } from "./reducers";

export const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();

const SetTransform = createTransform(
	(inboundState: Record<string, any>, key: string | number) => {
		if (["auth"].includes(key.toString())) {
			const {
				loading,
				deepLink,
				communityInvitation,
				credentials,
				...rest
			} = inboundState;

			return { ...rest };
		}

		return { ...inboundState };
	},
	(outboundState: Record<string, any>, key: string | number) => {
		return { ...outboundState };
	},
	{ whitelist: ["auth"] },
);

const persistConfig = {
	key: "root",
	storage,
	whitelist: ["auth"],
	transforms: [
		SetTransform,
		encryptTransform({
			secretKey: `my-super-secret-key`,
			onError: function (error) {
				console.error({ error });
			},
		}),
	],
};

// const myFirebaseApp = firebase.initializeApp({
//   apiKey: "AIzaSyDicbkp_6B1qqR0liVg0UkzS-zYV_HUcHU",
//   authDomain: "ugeniedev.firebaseapp.com",
//   databaseURL: "https://ugeniedev.firebaseio.com",
//   projectId: "ugeniedev",
//   storageBucket: "ugeniedev.appspot.com",
//   messagingSenderId: "897566672351",
//   appId: "1:897566672351:web:f94498667395f06cf5f06a",
//   measurementId: "G-36Z7HPZ2WE",
// });

// export const reduxSagaFirebase = new ReduxSagaFirebase(myFirebaseApp);

export default function configureStore() {
	const middlewares = [sagaMiddleware, createRouterMiddleware(history)];
	const persistedReducer = persistReducer(
		persistConfig,
		rootReducer(history),
	);
	const store = createStore(
		persistedReducer,
		composeWithDevTools(applyMiddleware(...middlewares)),
	);

	sagaMiddleware.run(sagas);
	const persistor = persistStore(store);

	return { store, persistor };
}

export * from "./actions";
export * from "./services";
