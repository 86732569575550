import { ICurrentSubscription } from "models";
import { ICommunityAppeareance } from "models/communities";

import {
	IAuthUserSetAction,
	IAuthLoadingSetAction,
	IAuthUserSetMembershipAction,
	IAuthInvitesGetAction,
	IInviteUpdateAction,
	IAuthSetDeepLinkAction,
	ISetUserCurrentSubscriptionAction,
	ISetAuthMemberTypeAction,
} from "./actions";

// ENUMS
export enum EAuthActionTypes {
	authInit = "auth/init",
	authLoadingSet = "auth/loading/set",
	authUserSet = "auth/user/set",
	authSignIn = "auth/sign/in",
	authSignOut = "auth/sign/out",
	authCheckToken = "auth/check/token",
	authSignUp = "auth/sign/up",
	authUserOnBoarding = "auth/user/onboarding",
	setUserMemberShipId = "auth/user/membership/set",
	getInvite = "invite/get",
	updateInvite = "invite/update",
	authByToken = "auth/token",
	setDeepLinkedSignUp = "auth/sign/up/deep-link",
	deleteAccount = "auth/delete/account",
	getUserCurrentSubscription = "auth/get/user/current/subscription",
	getUserCurrentSubscriptionCompleted = "auth/get/user/current/subscription/completed",
	setUserCurrentSubscription = "auth/set/user/current/subscription",
	getInvitationDataByCommunityLink = "get/invitation/data/by/community/link", // the community's specific link
	setMemberType = "auth/set/member/type",
}

// INTERFACES

export type TCurrentSubscription = ICurrentSubscription | string | undefined;

export enum EStartPagePath {
	login = "/auth/sign-in",
	signup = "/auth/sign-up",
	forgotPassword = "/auth/forgot-password",
}

export interface IUser {
	id: number;
	first_name: string;
	last_name: string;
	location: string;
	latitude: number;
	longitude: number;
	about_me: string;
	profession: null;
	picture: string;
	membership_id: string | number | null;
	interest_list: string[];
	membership_type: string;
	email: string;
	name: string;
	all_community_posts: boolean;
	only_replies_to_my_posts: boolean;
	no_lists: boolean;
	postcode: string;
	invite_token: string;
	member_directory_opt_in: boolean;
	community_id: number;
	joined_community_id: number;
	subset_list: { id: number; name: string; is_selected: boolean }[] | [];
	skill_list: { id: number; name: string; is_selected: boolean }[] | [];
	good_tag_list: { id: number; name: string; is_selected: boolean }[] | [];
	communities: any;
	groups: any;
	full_name?: string;
	current_plan_id: number;
	current_subscription: TCurrentSubscription;
	can_stream: boolean;
	eligible_for_trial: boolean;
	is_a_manager: boolean;
}

export interface IMemberType {
	id: number;
	name: string;
	taggings_count: number;
	group_id: number | null;
	priority: number;
}

export interface IAuthState {
	loading: boolean;
	credentials?: { user: IUser; invite: any; deepLink: any };
	communityInvitation: null | ICommunityInvitation;
	deepLink: any;
	memberType?: IMemberType;
}

export interface ICommunityInvitation {
	community: {
		id: number;
		name: string;
		picture: string;
		appearance: ICommunityAppeareance;
	};
	email?: string;
	member_type?: {
		id: number;
		name: string;
	};
	picture?: string;
}

export interface IAuthSignInActionPayload {
	type: EAuthActionTypes.authInit;
	payload: IAuthSignInPayload;
}

export interface IAuthSignInByTokenActionPayload {
	type: EAuthActionTypes.authByToken;
	payload: string;
}

export interface IAuthSignInPayload {
	email: string;
	password: string;
}
export interface IUserOnboardingState {
	picture: string;
	location: string;
	about: string;
}
export interface IAuthSignUpPayload {
	email: string;
	password: string;
	first_name: string;
	last_name: string;
	accepted_tcs: boolean;
	consented_to_pdp: boolean;
	is_nested_with_user: boolean;
}
export interface IAuthSignUpActionPayload {
	type: EAuthActionTypes.authSignUp;
	payload: IAuthSignUpPayload;
}
export interface IAuthLoadingPayload {
	loading: boolean;
}

// TYPES
export type TAuthActions =
	| IAuthLoadingSetAction
	| IAuthUserSetAction
	| IAuthUserSetMembershipAction
	| IAuthInvitesGetAction
	| IInviteUpdateAction
	| IAuthSetDeepLinkAction
	| ISetUserCurrentSubscriptionAction
	| ISetAuthMemberTypeAction;
